<!--
   Authors : initappz (Rahul Jograna)
  Website : https://initappz.com/
  App Name : ionic 5 Telecomalohic  app
  Created : 10-Sep-2020
  This App Template Source code is licensed as per the
  terms found in the Website https://initappz.com/license
  Copyright and Good Faith Purchasers © 2020-present initappz.
 -->
<!-- Footer -->
<footer class="page-footer font-small blue-grey lighten-5 ">
    <div class="feedback">
        <section class="container text-center mt-1 mb-3">
            <div data-mc-src="45cc9c95-3550-4d52-987d-bd5a41817792#youtube"></div>
        </section>
        <section class="container text-center mt-5 mb-1" style="height: 620px;">
            <h2 class="h1-responsive font-weight-bold pt-2 my-3">What Customers Says About Us</h2>
            <iframe style="overflow: hidden;" width="100%" height="100%" frameBorder="0" [src]="urlSafe2"></iframe>
        </section>
    </div>
    <div>
        <div class="container mb-3">
            <div class="row">
                <div class="col pt-3 dark-grey-text">
                    <h2 class="h1-responsive font-weight-bold">iPhone Sale</h2>


                    <div>
                        Cheapest iPhone | iPhone Market in Delhi | Second Hand Mobile | iPhone Sale
                        <br />

                        Any More Information Please Call
                        9625466915/8882404287
                        <br />
                        Address Plot No 76 1st Floor Mangol Pur Kalan Pathar Market
                        Near Tula Ram Public School Gali No.5 New Delhi 110085
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col">
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/mx-Gj29raBI"
                        title="Telecomalohic's Promotion Sale" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                    <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/0d6b934WcE8" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe> -->
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- Footer -->