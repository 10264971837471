import { Component, OnInit } from '@angular/core';
import { UtilService } from 'src/app/services/util.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-mobile-footer',
  templateUrl: './mobile-footer.component.html',
  styleUrls: ['./mobile-footer.component.scss']
})
export class MobileFooterComponent implements OnInit {
  urlSafe: SafeResourceUrl;
  urlSafe2: SafeResourceUrl;

  constructor(
    public sanitizer: DomSanitizer,
    public util: UtilService) { }

  ngOnInit(): void {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl("https://widget.tagembed.com/12044?view");
    this.urlSafe2 = this.sanitizer.bypassSecurityTrustResourceUrl("https://widget.tagembed.com/8363?view");
  }

}
