<!--
   Authors : initappz (Rahul Jograna)
  Website : https://initappz.com/
  App Name : ionic 5 Telecomalohic  app
  Created : 10-Sep-2020
  This App Template Source code is licensed as per the
  terms found in the Website https://initappz.com/license
  Copyright and Good Faith Purchasers © 2020-present initappz.
 -->
<!-- Footer -->
<footer class="page-footer font-small blue-grey lighten-5 ">
    <div class="feedback">
        <section class="container text-center mt-5 mb-1" style="height: 400px;">
            <h2 class="h1-responsive font-weight-bold pt-2 my-3">What Customers Says About Us</h2>
            <iframe style="overflow: hidden;" width="100%" height="100%" frameBorder="0" [src]="urlSafe2"></iframe>
        </section>
    </div>
    <div>
        <div class="container">
            <div class="row">
                <div class="col pt-5 dark-grey-text">
                    <h2 class="h1-responsive font-weight-bold">iPhone Sale</h2>

                    <div>
                        Cheapest iPhone | iPhone Market in Delhi | Second Hand Mobile | iPhone Sale
                        <br />

                        Any More Information Please Call
                        9625466915/8882404287
                        <br />
                        Address Plot No 76 1st Floor Mangol Pur Kalan Pathar Market
                        Near Tula Ram Public School Gali No.5 New Delhi 110085
                    </div>

                </div>
                <div class="col">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/mx-Gj29raBI" title="iPhone Sale"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                    <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/0d6b934WcE8" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe> -->
                </div>
            </div>
            <!-- <div class="row">
                <div class="col pt-5">
                    <h2 class="dark-grey-text">How to Order</h2>
                    <p class="dark-grey-text">
                        Please see the video for the same information
                    </p>
                </div>
                <div class="col">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/0d6b934WcE8"
                        title="Telecomalohic's Biggest Sale" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </div> -->
        </div>
    </div>
    <div style="background-color: rgba(0,0,0,0.8)">
        <div class="container">

            <!-- Grid row-->
            <div class="row py-4 d-flex align-items-center">

                <!-- Grid column -->
                <div class="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
                    <h6 class="mb-0">{{util.getString('Get connected with us on social networks!')}} </h6>
                </div>
                <!-- Grid column -->

                <!-- Grid column -->
                <div class="col-md-6 col-lg-7 text-center text-md-right social_div">

                    <!-- Facebook -->
                    <a class="fb-ic" target="_blank" href="https://www.facebook.com/telecomalohic">
                        <mdb-icon fab icon="facebook" style="color: #3b5998;" class="mr-4"></mdb-icon>
                    </a>
                    <!-- You Tube -->
                    <a class="fb-ic" target="_blank" href="https://www.youtube.com/channel/UCYyF3wWA90XtItHrkKbyc7A">
                        <mdb-icon fab icon="youtube" style="color: #CD201F;" class="mr-4"></mdb-icon>
                    </a>
                    <!-- Twitter -->
                    <a class="tw-ic" target="_blank" href="https://twitter.com/telecomalohic/">
                        <mdb-icon fab icon="twitter" style="color: #00acee;" class="mr-4"></mdb-icon>
                    </a>
                    <!-- Google +-->
                    <!-- <a class="gplus-ic">
            <mdb-icon fab fab icon="google-plus" class="white-text mr-4"></mdb-icon>
          </a> -->
                    <!--Linkedin -->
                    <!-- <a class="li-ic">
            <mdb-icon fab icon="linkedin" class="white-text mr-4"></mdb-icon>
          </a> -->
                    <!--Instagram-->
                    <a class="ins-ic" target="_blank" href="https://www.instagram.com/telecomalohic/?hl=en">
                        <mdb-icon fab icon="instagram" style="color: #E4405F;" class="mr-4"></mdb-icon>
                    </a>

                </div>
                <!-- Grid column -->

            </div>
            <!-- Grid row-->

        </div>
    </div>

    <!-- Footer Links -->
    <div class="container text-center text-md-left mt-5">

        <!-- Grid row -->
        <div class="row mt-3 dark-grey-text">

            <!-- Grid column -->
            <div class="col-md-6 col-lg-4 col-xl-3 mb-4">

                <!-- Content -->
                <h6 class="text-uppercase font-weight-bold footer_lbl">Telecomalohic</h6>
                <!-- <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;"> -->
                <p class="detail_content">
                    <a target="_blank"
                        href='https://play.google.com/store/apps/details?id=com.www.telecomalohic&utm_source=Website&utm_=Website&pid=pidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                        <img style="width: 100%;" alt='Get it on Google Play'
                            src='assets/imgs/google-play-badge.png' /></a>
                </p>
                <p class="detail_content">
                    <a target="_blank" href='https://apps.apple.com/in/app/telecomalohic/id1564654733'>
                        <img style="width: 100%;" alt='Get it on Google Play' src='assets/imgs/appstore.png' /></a>
                </p>

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-6 col-lg-2 col-xl-2 mx-auto mb-4">

                <!-- Links -->
                <h6 class="text-uppercase font-weight-bold footer_lbl">{{util.getString('Services')}} </h6>
                <!-- <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;"> -->
                <div class="flex_box" (click)="goToHome()">
                    <mdb-icon fas icon="angle-right"></mdb-icon> <a href="{{goToHome(false)}}"
                        class="dark-grey-text">{{util.getString('Home')}}
                    </a>
                </div>

                <div class="flex_box" (click)="goToOrders()">
                    <mdb-icon fas icon="angle-right"></mdb-icon> <a href="{{goToOrders(false)}}"
                        class="dark-grey-text">{{util.getString('Orders')}}
                    </a>
                </div>
                <div class="flex_box" (click)="util.publishCartBtn()">
                    <mdb-icon fas icon="angle-right"></mdb-icon> <a class="dark-grey-text">{{util.getString('Cart')}}
                    </a>
                </div>
                <div class="flex_box">
                    <mdb-icon fas icon="angle-right"></mdb-icon> <a target="_blank"
                        href="https://www.youtube.com/channel/UCYyF3wWA90XtItHrkKbyc7A" class="dark-grey-text">Video
                        Gallery
                    </a>
                </div>

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-6 col-lg-2 col-xl-2 mx-auto mb-4">

                <!-- Links -->
                <h6 class="text-uppercase font-weight-bold footer_lbl">{{util.getString('Useful links')}} </h6>
                <!-- <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;"> -->
                <div class="flex_box" (click)="goToAccount()">
                    <mdb-icon fas icon="angle-right"></mdb-icon> <a href="{{goToAccount(false)}}"
                        class="dark-grey-text">{{util.getString('Your
                        Account')}} </a>
                </div>
                <div class="flex_box" (click)="goToPrivacy()">
                    <mdb-icon fas icon="angle-right"></mdb-icon> <a href="{{goToPrivacy(false)}}"
                        class="dark-grey-text">{{util.getString('Privacy &
                        Terms')}}
                    </a>
                </div>
                <div class="flex_box" (click)="goToShipping()">
                    <mdb-icon fas icon="angle-right"></mdb-icon> <a href="{{goToShipping(false)}}"
                        class="dark-grey-text">Shipping Policy
                    </a>
                </div>
                <div class="flex_box" (click)="goToRefund()">
                    <mdb-icon fas icon="angle-right"></mdb-icon> <a href="{{goToRefund(false)}}" class="dark-grey-text">{{util.getString('Refund
                        Policy')}}
                    </a>
                </div>
                <div class="flex_box" (click)="about()">
                    <mdb-icon fas icon="angle-right"></mdb-icon> <a href="{{about(false)}}"
                        class="dark-grey-text">{{util.getString('About')}}
                    </a>
                </div>
                <div class="flex_box" (click)="goToHelp()">
                    <mdb-icon fas icon="angle-right"></mdb-icon> <a href="{{goToHelp(false)}}"
                        class="dark-grey-text">{{util.getString('Help')}}
                    </a>
                </div>
                <div class="flex_box" (click)="goToContact()">
                    <mdb-icon fas icon="angle-right"></mdb-icon> <a href="{{goToContact(false)}}"
                        class="dark-grey-text">{{util.getString('Contact')}}
                    </a>
                </div>

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-6 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">

                <!-- Links -->
                <h6 class="text-uppercase font-weight-bold footer_lbl">{{util.getString('Contact')}} </h6>
                <!-- <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;"> -->
                <div class="flex_box">
                    <mdb-icon fas icon="home"></mdb-icon>
                    <label>{{util.general && util.general.address ? util.general.address:'' }}</label>
                </div>
                <div class="flex_box">
                    <mdb-icon fas icon="envelope"></mdb-icon>
                    <label>{{util.general && util.general.email ? util.general.email:'' }}</label>
                </div>
                <div class="flex_box">
                    <mdb-icon fas icon="phone"></mdb-icon>
                    <label>+91 8882404287</label>
                </div>
                <div class="flex_box">
                    <mdb-icon fas icon="print"></mdb-icon>
                    <label for="">+91 9625466915</label>
                </div>

            </div>
            <!-- Grid column -->

        </div>
        <!-- Grid row -->

    </div>
    <!-- Footer Links -->
    <!-- Copyright -->
    <div class="footer-copyright text-center py-3" style="background: rgba(0,0,0,0.8);">© {{year}}
        {{util.getString('Copyright')}}:
        <a href="https://telecomalohic.com/"> telecomalohic.com</a>
    </div>
    <!-- Copyright -->
    <div style="background: rgba(0,0,0,0.6);">
        <div class="container py-3 text-black">
            <p>The Apple iPhone fundamentally changed the smartphone industry. In addition, their refurbished versions
                will
                bring economic change to the smartphone industry. A refurbished iPhone is a repaired and refurbished
                version
                of a used iPhone. The refurbishment process includes hardware and software testing. This includes
                verifying
                device connectivity, software and application functionality, sensor accuracy, and more. The broken part
                will
                be replaced with the original one, the software will be updated and restored to the factory version, and
                your iPhone will work like new.</p>
            <p>Apple is the ideal brand for user-oriented features, elegant design and premium quality. While the logo
                itself stands for high standards. However, these iPhones are not so easy to buy due to their high market
                price.
                But yes, refurbished phones only benefit when purchased from authorized vendors like Telecomalohic.
                Buying a
                new
                iPhone can cost a fortune, but refurbished versions are budget-friendly and inexpensive. The refurbished
                Apple
                iPhone is fully functional and comes with a 15-day warranty, ownership transfer, and return policy when
                purchased from Telecomalohic.</p>
            <p>The iPhones are expensive, so many people rely on buying a second-hand iPhone. However, buying a
                second-hand
                iPhone carries high risks and is not an economically and technically wise choice. However, you can buy
                it in
                several ways. Traditionally the most reliable way to buy a used phone from a friend or acquaintance. But
                Telecomalohic can help you buy the most popular iPhone with just a few clicks.</p>
            <p><b>Here you can find the best quality regenerated Apple iPhones at a reasonable price!</b></p>
            <p>
                Refurbished Apple iPhones offer a guaranteed and highly rated refurbished phone and must be purchased
                from
                an Authorized Distributor. Get a playback iPhone from Telecomalohic. Telecomalohic offers high quality
                mobile phones with certified components and spare parts. All refurbished iPhones undergo multiple
                quality
                checks and the final refurbished iPhone will appear completely genuine and brand new.</p>
            <p><b>Refurbished iPhones Available at Telecomalohic.</b></p>
            <p>You can buy different models and versions of iPhone. Frequently available models are Apple iPhoneX,
                iPhoneXs, iPhoneXR, iPhoneXS Max, iPhone8, iPhone8 Plus, iPhone7, iPhone7 Plus, iPhoneSE, iPhone6,
                iPhone6s, iPhone6s Plus, iPhone5, iPhone5c, iPhone5s, iPhone4s, and Apple iPhone11. Various offers
                for Apple iPhone11 Pro, iPhone11 Pro Max, iPhone12 Mini, iPhone12, iPhone12 Pro, iPhone12 Pro Max,
                iPhone13 Mini, iPhone 13, iPhone13 Pro, iPhone13 Pro Max. All available models can be selected from
                the refurbished zone in excellent or very good condition. Telecomalohic makes your
                shopping experience even more enjoyable by displaying the best deals at the top of your list.</p>
            <p><b>Is Refurbished iPhones smart investment?</b></p>
            <p>Buying a refurbished iPhone can save you up to 40% compared to buying a new device, fighting e-waste,
                reducing carbon emissions, and getting an ultra-high-end phone. increase. We have a full range of iPhone
                models, from the iPhone6, the iconic iPhone11 and iPhone11 Pro to the latest versions including the
                refurbished iPhone12 and even the iPhone13. Each model comes in a variety of specifications, from color
                to the amount of storage and everything in between, so you can find the right phone for you. </p>
            <p>Our certified used and refurbished iPhones are sourced from the highest quality suppliers. Each
                refurbished iPhone has undergone a 70-point technical check and is certified by an in-house expert. Each
                phone is professionally certified or refurbished, safely erased, thoroughly cleaned and then repacked.
            </p>
            <p>And we are confident of their quality, so all phones are backed by our 15-days warranty. All refurbished
                iPhones have been thoroughly tested and are in perfect condition. All come with a 15-days warranty.
                All devices will be shipped within 1 business day and will be shipped free of charge throughout India.
                All refurbished Apple iPhones have a free SIM unlock and offer a variety of payment
                options such as PayTM, PhonePe, Google Pay and other UPI payments.</p>
        </div>
    </div>
</footer>
<!-- Footer -->