<!--
   Authors : initappz (Rahul Jograna)
  Website : https://initappz.com/
  App Name : ionic 5 Telecomalohic  app
  Created : 10-Sep-2020
  This App Template Source code is licensed as per the
  terms found in the Website https://initappz.com/license
  Copyright and Good Faith Purchasers © 2020-present initappz.
 -->
<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="error-template">
        <h1>
          {{util.getString('Oops!')}}</h1>
        <h2>
          {{util.getString('404 Page Not Found')}} </h2>
        <div class="error-details">
          {{util.getString('Sorry, an error has occured, Requested page not found!')}}
        </div>
        <div class="error-actions">
          <a href="/" class="btn btn-default btn-lg">
            {{util.getString('Back')}} </a>
          <a href="/" class="btn btn-default btn-lg">
            <span class="glyphicon glyphicon-envelope"></span> {{util.getString('Contact Support')}} </a>
        </div>
      </div>
    </div>
  </div>
</div>
