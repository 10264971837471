import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
/*
  Authors : initappz (Rahul Jograna)
  Website : https://initappz.com/
  App Name : ionic 5 Telecomalohic  app
  Created : 10-Sep-2020
  This App Template Source code is licensed as per the
  terms found in the Website https://initappz.com/license
  Copyright and Good Faith Purchasers © 2020-present initappz.
*/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ProductItemComponent } from './product-item/product-item.component';
import { BadgeModule, CardsModule, IconsModule } from 'angular-bootstrap-md';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    NgbModule,
    BadgeModule,
    CardsModule,
    IconsModule,
    NgxJsonLdModule,
  ],
  declarations: [ProductItemComponent],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    NgbModule,
    BadgeModule,
    CardsModule,
    IconsModule,
    ProductItemComponent,
  ],
  providers: [

  ]
})
export class SharedModule { }
