import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { CartService } from 'src/app/services/cart.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss']
})
export class ProductItemComponent implements OnInit {
  @Input() product: any;

  constructor(public api: ApiService,
    public cart: CartService,
    public util: UtilService,
    private router: Router) { }

  ngOnInit(): void {
    this.product.quantiy = this.getQuanity()
    if (this.product.variations && this.product.size === '1' && this.product.variations !== '') {
      if (((x) => { try { JSON.parse(x); return true; } catch (e) { return false } })(this.product.status)) {
        this.product.variations = JSON.parse(this.product.variations);
        this.product['variant'] = 0;
      } else {
        this.product.variations = [];
        this.product['variant'] = 1;
      }
    } else {
      this.product.variations = [];
      this.product['variant'] = 1;
    }
  }

  getQuanity() {
    const data = this.cart.cart.filter(x => x.id === this.product.id);
    if (data?.length)
      return data[0].quantiy;
    return 0;
  }

  add() {
    this.product.quantiy = this.getQuanity();
    if (this.product.quantiy > 0) {
      this.product.quantiy = this.product.quantiy + 1;
      this.cart.addQuantity(this.product.quantiy, this.product.id);
    } else {
      this.product.quantiy = 1
      this.cart.addItem(this.product);
    }
  }

  remove() {
    this.product.quantiy = this.getQuanity();
    if (this.product.quantiy === 1) {
      this.product.quantiy = 0;
      this.cart.removeItem(this.product.id)
    } else {
      this.product.quantiy = this.product.quantiy - 1;
      this.cart.addQuantity(this.product.quantiy, this.product.id);
    }
  }

  goToSingleProduct() {
    const param: NavigationExtras = {
      queryParams: {
        id: this.product.objectID
      }
    };

    this.router.navigate(['product-detail'], param);
  }

  getUrl() {
    return `/product-detail?id=${this.product.objectID}`;
  }

}
