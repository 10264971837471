/*
  Authors : initappz (Rahul Jograna)
  Website : https://initappz.com/
  App Name : ionic 5 Telecomalohic  app
  Created : 10-Sep-2020
  This App Template Source code is licensed as per the
  terms found in the Website https://initappz.com/license
  Copyright and Good Faith Purchasers © 2020-present initappz.
*/
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import * as moment from 'moment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
    selector: 'app-footers',
    templateUrl: './footers.component.html',
    styleUrls: ['./footers.component.scss']
})
export class FootersComponent implements OnInit {
    @ViewChild('iframe') iframe: ElementRef;
    year: any;
    urlSafe: SafeResourceUrl;
    urlSafe2: SafeResourceUrl;
    constructor(
        private router: Router,
        public sanitizer: DomSanitizer,
        public util: UtilService) {
        this.year = moment().format('YYYY');
    }

    youtubeLinks: string[] = [
        "https://www.youtube.com/embed/rFzBNpyhL_0",
        "https://www.youtube.com/embed/pb4USjWHKbc",
        "https://www.youtube.com/embed/Um4Ly2jYnYM",
        "https://www.youtube.com/embed/qAk5q93cxy4"
    ];
    slideConfig = { "slidesToShow": 2, "slidesToScroll": 1 };

    ngOnInit(): void {
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl("https://widget.tagembed.com/12044?view");
        this.urlSafe2 = this.sanitizer.bypassSecurityTrustResourceUrl("https://widget.tagembed.com/8363?view");
    }

    goToCart(go: boolean = true) {
        const x = '/cart';
        if (go)
            this.router.navigate([x]);
        return x;
    }

    goToHome(go: boolean = true) {
        const x = '/home';
        if (go)
            this.router.navigate([x]);
        return x;
    }

    goToOrders(go: boolean = true) {
        const x = '/orders';
        if (go)
            this.router.navigate([x]);
        return x;
    }

    goToAccount(go: boolean = true) {
        const x = '/account';
        if (go)
            this.router.navigate([x]);
        return x;
    }

    goToShop(go: boolean = true) {
        const x = '/shop';
        if (go)
            this.router.navigate([x]);
        return x;
    }

    goToPrivacy(go: boolean = true) {
        const x = '/privacy-policy';
        if (go)
            this.router.navigate([x]);
        return x;
    }

    goToContact(go: boolean = true) {
        const x = '/contact';
        if (go)
            this.router.navigate([x]);
        return x;
    }

    goToRefund(go: boolean = true) {
        const x = '/refund-policy';
        if (go)
            this.router.navigate([x]);
        return x;
    }

    goToShipping(go: boolean = true) {
        const x = '/shipping-policy';
        if (go)
            this.router.navigate([x]);
        return x;
    }

    goToHelp(go: boolean = true) {
        const x = '/help';
        if (go)
            this.router.navigate([x]);
        return x;
    }

    about(go: boolean = true) {
        const x = '/about';
        if (go)
            this.router.navigate([x]);
        return x;
    }

    sanitizeUrl(urlSafe) { return this.sanitizer.bypassSecurityTrustResourceUrl(urlSafe); }

    slickInit(e) {
        console.log('slick initialized');
    }

    breakpoint(e) {
        console.log('breakpoint');
    }

    afterChange(e) {
        console.log('afterChange');
    }

    beforeChange(e) {
        console.log('beforeChange');
    }

}
