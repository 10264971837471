<ngx-json-ld>
	<script type="application/ld+json">
		{
			"@context": "https://schema.org/",
			"@type": "Product",
			"name": "{{ product.name }}",
			"image": "http://www.example.com/anvil_executive.jpg",
			"description": "Sleeker than ACME's Classic Anvil, the Executive Anvil is perfect for the business traveler looking
			for something to drop from a height.
			",
			"mpn": "925872",
			"brand": {
				"@type": "Thing",
				"name": "Apple"
			},
			"aggregateRating": {
				"@type": "AggregateRating",
				"ratingValue": "4.4",
				"reviewCount": "89"
			},
			"offers": {
				"@type": "Offer",
				"priceCurrency": "INR",
				"price": "{{product.sell_price}}",
				"priceValidUntil": "2020-11-05",
				"itemCondition": "http://schema.org/UsedCondition",
				"availability": "http://schema.org/InStock",
				"seller": {
					"@type": "Organization",
					"name": "Telecomalohic"
				}
			}
		}
	</script>
</ngx-json-ld>
<mdb-card class="border">
	<div class="badges">
		<mdb-badge *ngIf="product.discount != '0'" color="success">{{product.discount}}% OFF
		</mdb-badge>
		<mdb-badge *ngIf="product.in_stoke < 4 && product.in_stoke > 0" color="danger">
			{{product.in_stoke}} units left
		</mdb-badge>
		<mdb-badge *ngIf="product.in_stoke >= 4 && product.in_stoke <= 10" color="warning">
			Few units left
		</mdb-badge>
		<mdb-badge *ngIf="product.in_stoke == 0" color="danger">Out of Stock</mdb-badge>
	</div>
	<img class="" (click)="goToSingleProduct()" [src]="api.mediaURL+product.cover || 'assets/imgs/user.png'"
		[alt]="product.name" />
	<mdb-card-body class="p-0">
		<ul class="list-group">
			<li class="list-group-item border-0" (click)="goToSingleProduct()">
				<div class="name"><a href="{{getUrl()}}" fragment="education">
						{{ product.name }}</a></div>
			</li>
			<li class="list-group-item border-0"
				*ngIf="product.size == '0' && (product.have_gram == 1 || product.have_kg == 1 || product.have_liter == 1 || product.have_ml == 1 || product.have_pcs == 1)">
				<div class="itemsKind">
					<span *ngIf="product.have_gram == 1"> {{product.gram}} {{util.getString('grams')}} </span>
					<span *ngIf="product.have_kg == 1"> {{product.kg}} {{util.getString('kg')}} </span>
					<span *ngIf="product.have_liter == '1'"> {{product.liter}} {{util.getString('ltr')}} </span>
					<span *ngIf="product.have_ml =='1'"> {{product.ml}} {{util.getString('ml')}} </span>
					<span *ngIf="product.have_pcs == 1"> {{product.pcs}} {{util.getString('pcs')}} </span>
				</div>
			</li>
			<li class="list-group-item border-0" *ngIf="product.size =='0'">
				<div class="itemsKind ion-text-wrap" *ngIf="product.discount == '0'">
					<strong>{{product.original_price | currency:'INR'}}</strong>
				</div>
				<div class="itemsKind ion-text-wrap" *ngIf="product.discount != '0'">
					<span class="cut">{{product.original_price | currency:'INR'}}</span>&nbsp;
					<strong>{{product.sell_price | currency:'INR'}}</strong>
				</div>
			</li>
			<li class="list-group-item d-flex justify-content-between flex-wrap border-0 p-2"
				*ngIf="cart.itemId.includes(product.id)" color="brand" lines="none">
				<button color="light" class="btn btn-link btn-brand align-self-start p-0 pr-5" (click)="remove()">
					<mdb-icon fas icon="minus-circle"></mdb-icon>
				</button>
				{{product.quantiy}}
				<button *ngIf="product.quantiy < product.in_stoke"
					class="btn btn-link btn-brand p-0 pl-5 align-self-end" (click)="add()">
					<mdb-icon fas icon="plus-circle"></mdb-icon>
				</button>
				<button *ngIf="product.quantiy >= product.in_stoke"
					class="btn btn-link btn-brand p-0 pl-5 align-self-end">
					<mdb-icon fas icon="x"></mdb-icon>
				</button>
			</li>
		</ul>
		<button type="button" class="btn-brand btn btn-block m-0" color="primary" mdbWavesEffect block="true"
			(click)=" add()" *ngIf="!cart.itemId.includes(product.id) && product.in_stoke >= '1'">
			Buy Now
			<mdb-icon fas class="float-right pull-right" icon="shopping-bag"></mdb-icon>
		</button>
	</mdb-card-body>
</mdb-card>