/*
  Authors : initappz (Rahul Jograna)
  Website : https://initappz.com/
  App Name : ionic 5 Telecomalohic  app
  Created : 10-Sep-2020
  This App Template Source code is licensed as per the
  terms found in the Website https://initappz.com/license
  Copyright and Good Faith Purchasers © 2020-present initappz.
*/
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	baseURL: 'https://api.telecomalohic.com/index.php/',
	mediaURL: 'http://media.telecomalohic.com/uploads/',
	onesignal: {
		appId: 'a7caac1b-c916-469f-a690-d2eb2ab6a184',
		googleProjectNumber: '84395695930',
		restKey: 'ZjBjOThkMmItZDI2ZS00ZWZiLTkyZjUtZWJjODQ0ZDRmZmVh'
	},
	general: {
		symbol: '₹',
		code: 'INR'
	},
	authToken: '2548727',
	payments: {
		stripe_key: 'sk_test_51IBgJ5HGW8b9dXo9F3xow6l6MbgAiuhHx7Klw4BMRTdCQ792iv9qBEOXUSqRU4xwkg2IdNuyGNHbZjcjXCwueJ3900gGdqmkqZ'
	},
	algolia: {
		appId: 'KYH5U8DR4C',
		apiKey: 'c96f6c986c020c56b5b0ec543e3838d2'
	},
	firebase: {
		apiKey: "AIzaSyC5dlqe-7H0nBhGR0ErUX8kUfOY7K_aIX8",
		authDomain: "telecomalohic-8199b.firebaseapp.com",
		databaseURL: "https://telecomalohic-8199b-default-rtdb.asia-southeast1.firebasedatabase.app",
		projectId: "telecomalohic-8199b",
		storageBucket: "telecomalohic-8199b.appspot.com",
		messagingSenderId: "84395695930",
		appId: "1:84395695930:web:908253de4c6a7bdefd7a03",
		measurementId: "G-7TEQ8B01GW"
	},
	hostUrl: 'https://telecomalohic.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
